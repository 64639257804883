import React, { useState } from "react";
import dayjs from "dayjs";
import styles from "../_css/ScenarioDraftBody.module.css";
import iconOrange from "../../assets/icons/circleOrange.svg";
import clsx from "clsx";
import { TEventMdl } from "scenarios/_models/EventMdl";
import { SectionsRenderer } from "scenarios/sections/SectionsRender";
import { observer } from "mobx-react";
import { EventPage } from "scenarios/events/EventPage";
import { Dialog } from "common/ui/dialogs/Dialog";
import { TPeriodMdl } from "scenarios/_models/PeriodMdl";
import { Edit } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { TEXT_SIZES } from "scenarios/_models/ScenarioMdl";

type Props = {
    event: TEventMdl;
    period: TPeriodMdl;
    isEditable: boolean;
};

export const ScenarioDraftEvent = observer((props: Props) => {
    const date = dayjs(props.event.date).format("DD MMM YYYY");
    const [open, setOpen] = useState(false);
    const { scenarioStore, publicScenarioView } = useScenarioStore();

    const [titleOver, setTitleOver] = useState(false);

    const hasTitleOrDate = !!props.event.title || !!props.event.date;

    const doesNotDisplay = publicScenarioView && props.event.private;
    if (doesNotDisplay) return null;

    return (
        <div className={"mt_15"}>
            <div className={"avoidBreak"}>
                <div
                    onClick={() => {
                        if (props.isEditable && scenarioStore.isCollaboratorEventAuthorOrScenarioOwner(props.event)) {
                            setOpen(true);
                        }
                    }}
                    className={clsx("flex_row_center position_relative avoidBreak", styles.eventDate)}
                >
                    {hasTitleOrDate && <img alt={"orange dot"} src={iconOrange} className={styles.bullet} />}
                    {props.event.title && (
                        <div className={styles.contentTitleAndEdit}>
                            <h6 className={clsx("ml_15 mt_2 mb_20", styles.eventTitle)}>{props.event.title}</h6>
                            {props.isEditable &&
                                scenarioStore.isCollaboratorEventAuthorOrScenarioOwner(props.event) &&
                                titleOver && (
                                    <IconButton className={clsx(styles.btnEdit, "cursor_pointer no-print")}>
                                        <Edit />
                                    </IconButton>
                                )}
                        </div>
                    )}

                    {props.event.date && <div className={"flex-1"} />}
                    {props.event.date && <div className={clsx("ml_20", styles.date)}>{date}</div>}
                </div>

                {props.event.text && (
                    <p
                        className={clsx(styles.eventText, "mb_20 avoidBreak", {
                            scenario_text_small: props.event.textSize === TEXT_SIZES.SMALL,
                            scenario_text_medium: props.event.textSize === TEXT_SIZES.MEDIUM || !props.event.textSize,
                            scenario_text_large: props.event.textSize === TEXT_SIZES.LARGE,
                        })}
                    >
                        {props.event.text}
                    </p>
                )}
            </div>
            <div className={styles.sectionRender}>
                <SectionsRenderer eventId={props.event.id} isEditable={false} />
            </div>

            <Dialog
                borderRadius={0}
                backgroundColor={"white"}
                padding={0}
                fullScreen
                open={open}
                disableEscapeKeyDown
                onClose={() => setOpen(false)}
            >
                <EventPage onClose={() => setOpen(false)} eventId={props.event.id} periodId={props.period._id} />
            </Dialog>
        </div>
    );
});
