import React from "react";
import clsx from "clsx";
import styles from "scenarios/_css/scenarioPage.module.css";
import { userStore } from "users/_stores/userStore";
import { URLS } from "_configs/URLS";
import { ArrowBackOutlined } from "@material-ui/icons";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { ScenarioDraftHeader } from "scenarios/draft/ScenarioDraftHeader";
import { ScenarioDraftBody } from "scenarios/draft/ScenarioDraftBody";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { observer } from "mobx-react";
import { Logo } from "main/Logo";
import { ImageGalleryModal } from "images/ImageGalleryModal";

type Props = {
    isEditable: boolean;
};

export const ScenarioDraftPage = observer((props: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const store = useScenarioStore();
    const { scenarioStore } = store;
    const scenario = scenarioStore.scenario;

    const isEditable =
        props.isEditable && (userStore.user?._id === scenario.user || scenarioStore.isCollaborator() != undefined);

    return (
        <div className={clsx(styles.container, "flex_row")}>
            <div className={clsx(styles.body, "flex-1 position_relative")}>
                {isEditable && (
                    <div
                        className={clsx(styles.btnFixedGoBack, "no-print")}
                        onClick={() => history.push(URLS.scenarios.scenario(scenario._id))}
                    >
                        <>
                            <ArrowBackOutlined fontSize={"large"} className={"mr_5"} />
                            {t("draftPage.back")}
                        </>
                    </div>
                )}

                <div
                    className={clsx(styles.btnScrollToTop, "no-print flex_center_center")}
                    onClick={() => window.scrollTo(0, 0)}
                >
                    <ArrowUpwardIcon fontSize={"large"} />
                </div>
                <ScenarioDraftHeader isEditable={isEditable} />
                <ScenarioDraftBody isEditable={isEditable} />
                {isEditable && (
                    <>
                        <div
                            className={clsx(styles.linkBack, "no-print")}
                            onClick={() => history.push(URLS.scenarios.scenario(scenario._id))}
                        >
                            {t("draftPage.back").toUpperCase()}
                        </div>
                    </>
                )}
            </div>
            <div className={styles.numberPage}> </div>

            <div className={clsx("divFooter", styles.footer)}>
                <Logo />
            </div>
            <ImageGalleryModal />
        </div>
    );
});
