import React from "react";
import styles from "./_css/eventPageSideBar.module.css";
import clsx from "clsx";
import { sectionStore } from "scenarios/sections/_stores/sectionStore";
import { EditorForm } from "scenarios/sections/editor/EditorForm";
import { observer } from "mobx-react";
import { EventBookmarksList } from "scenarios/events/EventBookmarksList";
import { TEventMdl } from "scenarios/_models/EventMdl";
import closeSidebar from "../../assets/icons/closeSidebar.svg";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { useTranslation } from "react-i18next";

type Props = {
    event: TEventMdl;
    onTemporaryEventSave: (ev: TEventMdl) => void;
};

export const EventPageSideBar = observer((props: Props) => {
    const store = useScenarioStore();
const { scenarioStore } = store;
    const { t } = useTranslation();
    return (
        <div
            className={clsx("position_relative flex_row", styles.sidebar, {
                [styles.sidebarOpen]: scenarioStore.sidebarOpened,
                [styles.sidebarClose]: !scenarioStore.sidebarOpened,
            })}
        >
            <div
                className={clsx(styles.section, "flex-1 p_20 pt_50", {
                    [styles.sectionEditorForm]: sectionStore.editingSection,
                })}
            >
                <div className={styles.btnCloseOrOpenSideBar}>
                    <div
                        className={clsx(styles.closeBtn, "position_absolute cursor_pointer")}
                        onClick={() => scenarioStore.toggleSidebar()}
                    >
                        <img height={35} src={closeSidebar} alt={"close icon"} />
                    </div>
                </div>
                {sectionStore.editingSection ? (
                    <>
                        <h4 className={"pb_20 text_big"}>{t("editor.event.editor")}</h4>
                        <EditorForm />
                    </>
                ) : (
                    <div>
                        <h4>{t("editor.event.tagIt.title")}</h4>
                        <h5 className={"pt_10"}>{t("editor.event.tagIt.text")}</h5>
                        <div className={"mt_20 flex_row flexWrap_wrap"}>
                            <EventBookmarksList onTemporaryEventSave={props.onTemporaryEventSave} event={props.event} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});
