import React from "react";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {
    imageUrl: string;
    alt?: string;
    title?: string;
    isEditable?: boolean;
    onEdit?: () => void;
};

export function ImageSection({ ...props }: Props) {
    const store = useScenarioStore();
const { scenarioStore } = store;
    return (
        <div
            onClick={(e) => {
                if (props.isEditable) {
                    e.preventDefault();
                    e.stopPropagation();
                    props.onEdit ? props.onEdit() : undefined;
                    // scenarioStore.toggleSidebar();
                }
            }}
            className={"flex avoidBreak"}
        >
            <LazyLoadImage style={{ width: "100%" }} src={props.imageUrl} alt={props.alt} title={props.title} />
        </div>
    );
}
