import { socketStore } from "scenarios/socket/_stores/socketStore";
import { useEffect, useState } from "react";
import { TEventMdl } from "scenarios/_models/EventMdl";
import { defaultPeriod, TPeriodMdl } from "scenarios/_models/PeriodMdl";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { DropResult } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const useScenarioPeriod = (period: TPeriodMdl, position: number) => {
    const [events, setEvents] = useState<TEventMdl[]>(period.events);
    const store = useScenarioStore();
const { scenarioStore } = store;
    const { t } = useTranslation();

    useEffect(() => {
        setEvents(period.events);
    }, [period]);
    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const _events = [...events];
        const movableEvent = _events[result.source.index];
        if (result.destination.index - result.source.index > 0) {
            for (let i = result.source.index; i < result.destination.index; i++) {
                _events[i] = _events[i + 1];
            }
        } else {
            for (let i = result.source.index; i > result.destination.index; i--) {
                _events[i] = _events[i - 1];
            }
        }
        _events[result.destination.index] = movableEvent;
        socketStore.moveEvent(scenarioStore.scenario._id, period, _events);
        scenarioStore.editPeriod({ ...period, events: _events });
    };

    const setSelectedPeriod = () => {
        scenarioStore.selectedPeriod = {
            period,
            position,
        };
    };

    const unsetSelectedPeriod = () => {
        scenarioStore.selectedPeriod = { position: undefined, period: undefined };
    };

    const addPeriod = () => {
        const period = defaultPeriod();
        scenarioStore.addPeriod(period, scenarioStore.selectedPeriod.position);
        socketStore.newPeriod(scenarioStore.scenario._id, period, position);
        toast.success(t("editor.period.newSuccess"), { position: "bottom-center" });
    };

    return { events, onDragEnd, setSelectedPeriod, unsetSelectedPeriod, addPeriod };
};

export default useScenarioPeriod;
