import React from "react";
import { Input } from "common/ui/forms/Input";
import styles from "./_css/scenarioSearch.module.css";
import clsx from "clsx";
import { useScenarioStore } from "scenarios/ScenarioContext";

type Props = {
    open: boolean;
};

export function ScenarioSearch(props: Props) {
    const store = useScenarioStore();
const { scenarioStore } = store;
    return (
        <div
            className={clsx("position_absolute", styles.container, {
                [styles.containerOpen]: props.open,
            })}
        >
            <Input onChange={(e) => scenarioStore._setSearch(e.target.value)} className={styles.input} />
        </div>
    );
}
