import React from "react";
import { observer } from "mobx-react";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { WrapperComponent } from "scenarios/events/WrapperComponent";

type Props = {
    eventId: string;
    isEditable?: boolean;
};

export const SectionsRenderer = observer((props: Props) => {
    const store = useScenarioStore();
    const { scenarioStore } = store;
    const { event } = scenarioStore.getEvent(props.eventId);
    if (!event) return null;
    return (
        <div>
            {event.sections?.map((component) => {
                if (!component.props?.id) return null;
                return (
                    <WrapperComponent
                        className={"avoidBreak"}
                        {...props}
                        key={component.props.id}
                        component={component}
                        event={event}
                    />
                );
            })}
        </div>
    );
});
