import React, { useState } from "react";
import styles from "./_css/scenarioHeader.module.css";
import clsx from "clsx";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { observer } from "mobx-react";
import galleryIcon from "../assets/icons/galleryIcon.svg";
import shareIcon from "../assets/icons/share.svg";
import searchIcon from "../assets/icons/searchIcon.svg";
import addCollaborateurIcon from "../assets/icons/addCollaborateurIcon.svg";
import { defaultPeriod } from "scenarios/_models/PeriodMdl";
import { useTranslation } from "react-i18next";
import { InviteCollaboratorDialog } from "scenarios/collaborators/InviteCollaboratorDialog";
import { Dialog } from "common/ui/dialogs/Dialog";
import { ScenarioSearch } from "scenarios/ScenarioSearch";
import { useHistory, useLocation } from "react-router";
import { UiSelect } from "common/ui/mui/forms/UiSelect";
import { MenuItem } from "@material-ui/core";
import i18next from "i18next";
import { URLS } from "_configs/URLS";
import { userStore } from "users/_stores/userStore";
import { imagesStore } from "images/_stores/imagesStore";
import { SharedHistoryDialog } from "scenarios/sections/SharedHistoryDialog";
import { socketStore } from "scenarios/socket/_stores/socketStore";

export const ScenarioHeader = observer(() => {
    const store = useScenarioStore();
const { scenarioStore } = store;
    const lang = i18next.language;
    const { hash } = useLocation();
    const [openInvitation, setOpenInvitation] = useState(false);
    const [openShared, setOpenShared] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [selectedPeriodId, setSelectedPeriodId] = useState(
        scenarioStore?.periods.find((period) => period._id === hash)?._id ?? scenarioStore?.periods?.[0]?._id ?? "",
    );
    const history = useHistory();
    const { t } = useTranslation();

    const canEdit = !scenarioStore.isCollaborator() && !userStore.isAdmin();

    return (
        <div className={clsx(styles.header, "position_relative")}>
            <div
                className={clsx(styles.container, "ph_30 pv_20 flex_row_center justifyContent_spaceBetween", {
                    [styles.sidebarClosed]: !scenarioStore.sidebarOpened,
                })}
            >
                <div className={clsx(styles.firstLineBtn, "flex_row alignItems_center")}>
                    {canEdit && (
                        <UiButton
                            color={"primary"}
                            variant={"contained"}
                            onClick={() => {
                                const period = defaultPeriod();
                                scenarioStore.addPeriod(period);
                                socketStore.newPeriod(scenarioStore.scenario._id, period);
                                setTimeout(() => {
                                    const element = document.getElementById(
                                        scenarioStore.scenario.periods[scenarioStore.scenario.periods.length - 1]._id,
                                    );
                                    if (element) {
                                        window.scrollTo({ top: element.offsetTop, behavior: "smooth" });
                                    }
                                }, 300);
                            }}
                            className={styles.btnAddChapter}
                        >
                            <div className={styles.labelHeader}>{t("editor.period.primaryAddBtn")} </div>
                        </UiButton>
                    )}
                    <div>
                        <UiSelect
                            onChange={(e) => {
                                setSelectedPeriodId(e.target.value as string);
                                setTimeout(() => {
                                    const element = document.getElementById(e.target.value as string);
                                    if (element) {
                                        window.scrollTo({ top: element.offsetTop, behavior: "smooth" });
                                    }
                                }, 300);
                            }}
                            defaultValue={selectedPeriodId}
                        >
                            {scenarioStore.periods.map((period) => {
                                return (
                                    <MenuItem key={period._id} value={period._id} className={styles.item}>
                                        {period.title}
                                    </MenuItem>
                                );
                            })}
                        </UiSelect>
                    </div>
                </div>
                <div className={clsx(styles.secondLineBtn, "flex_row alignItems_center")}>
                    <div className={"flex-1"} />

                    <div className={"flex_row"}>
                        <div className={clsx(styles.contentIcons, "position_relative")}>
                            <img
                                className={styles.icons}
                                onClick={() => setOpenSearch(!openSearch)}
                                title={t("editor.tooltips.search")}
                                src={searchIcon}
                                alt={t("editor.tooltips.search")}
                            />
                            <ScenarioSearch open={openSearch} />
                        </div>

                        <div className={styles.contentIcons}>
                            <img
                                className={styles.icons}
                                src={galleryIcon}
                                title={t("editor.tooltips.gallery")}
                                alt={"gallery icon"}
                                onClick={() => {
                                    imagesStore.setGalleryState({
                                        onSelect: (_imageUrl, _height, _width) => -1,
                                    });
                                }}
                            />
                        </div>

                        {/*<div className={styles.contentIcons}>*/}
                        {/*    <img className={styles.icons} src={helpIcon} alt={"help icon"} />*/}
                        {/*</div>*/}
                        {!scenarioStore.isCollaborator() && !userStore.isGuest() && (
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setOpenInvitation(true);
                                }}
                                className={styles.contentIcons}
                            >
                                <img
                                    title={t("editor.tooltips.collaborators")}
                                    className={styles.icons}
                                    src={addCollaborateurIcon}
                                    alt={"info icon"}
                                />
                            </div>
                        )}
                        {!scenarioStore.isCollaborator() && !userStore.isGuest() && (
                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (!scenarioStore.scenario.token) scenarioStore.refreshToken();
                                    setOpenShared(true);
                                }}
                                className={styles.contentIcons}
                            >
                                <img
                                    title={t("editor.tooltips.share")}
                                    className={styles.icons}
                                    src={shareIcon}
                                    alt={"info icon"}
                                />
                            </div>
                        )}
                    </div>
                    <UiButton
                        className={styles.btnPreview}
                        onClick={() => {
                            history.push(URLS.scenarios.draft(scenarioStore.scenario._id, lang));
                        }}
                        color={"primary"}
                        variant={"outlined"}
                    >
                        {t("scenarioCard.preview")}
                    </UiButton>
                </div>
            </div>
            <div className={styles.placeholder} />
            <Dialog maxWidth={"sm"} fullWidth open={openInvitation} onClose={() => setOpenInvitation(false)}>
                <InviteCollaboratorDialog onClose={() => setOpenInvitation(false)} />
            </Dialog>
            <Dialog maxWidth={"sm"} fullWidth open={openShared} onClose={() => setOpenShared(false)}>
                <SharedHistoryDialog />
            </Dialog>
        </div>
    );
});
