import { TextAreaFront } from "common/ui/forms/TextAreaFront";
import React, { CSSProperties } from "react";
import { useScenarioStore } from "scenarios/ScenarioContext";
import _ from "lodash";
import styles from "./_css/textSectionEditor.module.css";
import clsx from "clsx";
import { sectionStore } from "scenarios/sections/_stores/sectionStore";

type Props = {
    text: string;
    id: string;
    componentId?: string;
    styles?: CSSProperties;
    isEditable?: boolean;
    onEdit?: () => void;
    textSize?: "small" | "medium" | "large";
};

export function TextSectionEditor(props: Props) {
    if (!props.textSize && sectionStore.editingSection)
        sectionStore.editingSection.props = { ...sectionStore.editingSection.props, textSize: "small" };

    const store = useScenarioStore();
const { scenarioStore } = store;
    return (
        <div
            style={{ ...props.styles }}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <TextAreaFront
                className={clsx(styles.textarea, {
                    [styles.textSmall]: props.textSize === "small",
                    [styles.textMedium]: props.textSize === "medium",
                    [styles.textLarge]: props.textSize === "large",
                })}
                style={{ ...props.styles }}
                rows={10}
                value={props.text}
                onValueChange={(text) => {
                    scenarioStore.editSection(props.id, {
                        props: { ..._.omit(props, ["onEdit", "isEditable", "componentId"]), text },
                    });
                }}
            />
        </div>
    );
}
