import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IFolderMdl } from "folders/_models/FolderMdl";
import { useScenarioStore } from "scenarios/ScenarioContext";
import styles from "scenarios/collaborators/_css/inviteCollaborator.module.css";
import { Dialog } from "common/ui/dialogs/Dialog";
import { InputFront } from "common/ui/forms/InputFront";
import { InputFrontBlock } from "common/ui/forms/InputFrontBlock";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import clsx from "clsx";
import { userStore } from "users/_stores/userStore";

type Props = {
    open: boolean;
    onClose: () => void;
    onSuccess: (updatedFolder: Omit<IFolderMdl, "_id"> & { _id?: string }) => void;
    folder?: Omit<IFolderMdl, "_id"> & { _id?: string };
};

export function FolderDialog(props: Props) {
    const store = useScenarioStore();
const { scenarioStore } = store;
    const { t } = useTranslation();
    const [folder, setFolder] = useState<Omit<IFolderMdl, "_id"> & { _id?: string }>(
        props.folder ?? { name: "New folder", scenarioId: scenarioStore.scenario._id, userId: userStore.user?._id },
    );

    useEffect(() => {
        setFolder(
            props.folder ?? { name: "New folder", scenarioId: scenarioStore.scenario._id, userId: userStore.user?._id },
        );
    }, [props.folder]);

    return (
        <Dialog open={props.open} onClose={props.onClose} maxWidth="md">
            <div style={{ width: 750, maxWidth: "100%" }}>
                <div className={styles.container}>
                    <div className={styles.title}>
                        {!props.folder?._id ? (
                            <>Nouveau dossier</>
                        ) : (
                            <>
                                {t("words.rename")} {props.folder.name}
                            </>
                        )}
                    </div>
                    <InputFrontBlock className={"mt_50"} label={t("words.edit")}>
                        <InputFront
                            value={folder.name}
                            onValueChange={(folderName) => setFolder({ ...folder, name: folderName })}
                        />
                    </InputFrontBlock>
                    <div className={clsx("flex_row_center mt_50 pb_20", styles.contentBtn)}>
                        <div className={"flex-1"} />
                        <UiButton
                            variant={"outlined"}
                            onClick={() => props.onClose()}
                            color={"primary"}
                            className={clsx("mr_10", styles.button)}
                        >
                            {t("words.cancel")}
                        </UiButton>
                        <UiButton
                            className={styles.button}
                            onClick={() => {
                                props.onSuccess(folder);
                            }}
                            disabled={!folder.name}
                            variant={"contained"}
                            color={"primary"}
                        >
                            {t("words.save")}
                        </UiButton>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
