import React, { useState } from "react";
import { observer } from "mobx-react";
import { foldersStore } from "folders/_stores/foldersStore";
import { Grid } from "@material-ui/core";
import clsx from "clsx";
import styles from "folders/_css/newFolderBlock.module.css";
import Folder from "@material-ui/icons/Folder";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { imagesStore } from "images/_stores/imagesStore";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { IFolderMdl } from "folders/_models/FolderMdl";
import { userStore } from "users/_stores/userStore";

type Props = {
    onFolderClick: (folderId: string | undefined) => void;
    onFolderEdit: (folder?: IFolderMdl) => void;
};

export const FoldersLoaded = observer((props: Props) => {
    const store = useScenarioStore();
const { scenarioStore } = store;
    const [folderDragOver, setFolderDragOver] = useState<string>();
    return (
        <>
            {foldersStore.currentFolders.map((folder) => {
                return (
                    <Grid key={folder._id} item xs={12} md={3} lg={2}>
                        <div
                            onDragOver={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setFolderDragOver(folder._id);
                            }}
                            onDragLeave={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setFolderDragOver(undefined);
                            }}
                            onDrop={(_e) => {
                                if (foldersStore.draggedImage) {
                                    imagesStore.update({
                                        ...foldersStore.draggedImage,
                                        folderId: folder._id,
                                    });
                                }
                                setFolderDragOver(undefined);
                            }}
                            className={clsx(styles.container, "flex_center_center", {
                                [styles.isDraggedOver]: folderDragOver === folder._id,
                            })}
                            onClick={() => props.onFolderClick(folder._id)}
                        >
                            <Folder style={{ fontSize: "5rem" }} />
                            <div className={styles.text}>{folder.name}</div>
                            {(!folder.userId || folder.userId === userStore.user?._id || scenarioStore.isOwner()) && (
                                <div className={styles.delete}>
                                    <IconButton
                                        style={{ backgroundColor: "#000" }}
                                        className={styles.deleteIcon}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            if (
                                                window.confirm(
                                                    "Êtes-vous sûr de vouloir supprimer ce dossier '" +
                                                        folder.name +
                                                        "' ?",
                                                )
                                            ) {
                                                foldersStore.deleteFolder(folder._id).then(() => {
                                                    imagesStore.fetchImages(scenarioStore.scenario._id);
                                                });
                                            }
                                        }}
                                        size="small"
                                    >
                                        <DeleteIcon style={{ color: "#FFF" }} />
                                    </IconButton>
                                </div>
                            )}
                            {(!folder.userId || folder.userId === userStore.user?._id || scenarioStore.isOwner()) && (
                                <div className={styles.edit}>
                                    <IconButton
                                        style={{ backgroundColor: "green" }}
                                        className={styles.deleteIcon}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            props.onFolderEdit(folder);
                                        }}
                                        size="small"
                                    >
                                        <EditIcon style={{ color: "#FFF" }} />
                                    </IconButton>
                                </div>
                            )}
                        </div>
                    </Grid>
                );
            })}
        </>
    );
});
