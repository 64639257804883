import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { AddPeriodBtn } from "scenarios/periods/AddPeriodBtn";
import { PAGE_REF } from "scenarios/ScenarioPage";
import { ADD_BUTTON_HEIGHT, HEADER_HEIGHT, HEADER_HEIGHT_MOBILE, SCENARIO_HEADER_HEIGHT } from "_configs/sizes";
import clsx from "clsx";
import styles from "./_css/addPeriodObserver.module.css";
import { useTranslation } from "react-i18next";
import { useWindowWidthAfterFirstRender } from "common/_utils/hookUtils";

export const AddPeriodObserver = observer(() => {
    const store = useScenarioStore();
    const { scenarioStore } = store;
    const [position, setPosition] = useState(0);
    const { t } = useTranslation();

    const windowWidth = useWindowWidthAfterFirstRender();

    useEffect(() => {
        if (scenarioStore.selectedPeriod?.period) {
            PAGE_REF.current?.addEventListener("mousemove", (e: MouseEvent) => {
                // ADD_BUTTON_HEIGHT / 2 : To set the cursor in the center of the button
                setPosition(
                    e.pageY -
                        (windowWidth <= 1000 ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT) -
                        SCENARIO_HEADER_HEIGHT -
                        ADD_BUTTON_HEIGHT / 2,
                );
            });
        }
    }, [scenarioStore.selectedPeriod, windowWidth]);

    useEffect(() => {
        return () => {
            PAGE_REF.current?.removeEventListener("mousemove", () => console.info("remove"));
        };
    }, []);

    if (!scenarioStore.selectedPeriod?.period) return null;
    return (
        <div className={clsx("position_absolute cursor_pointer", styles.container)} style={{ top: position }}>
            <AddPeriodBtn />
        </div>
    );
});
