import React from "react";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { ScenarioPeriod } from "scenarios/periods/ScenarioPeriod";
import { observer } from "mobx-react";
import styles from "./_css/scenarioBody.module.css";
import clsx from "clsx";
import { useHash } from "common/anchors/useHash";
import { ImageGalleryModal } from "images/ImageGalleryModal";
import { AddPeriodObserver } from "scenarios/AddPeriodObserver";

export const ScenarioBody = observer(() => {
    const store = useScenarioStore();
const { scenarioStore } = store;
    useHash();

    return (
        <div
            className={clsx(styles.container, "position_relative")}
            onMouseLeave={() => (scenarioStore.selectedPeriod = { position: undefined, period: undefined })}
        >
            <AddPeriodObserver />
            {scenarioStore.periods.map((period, index) => {
                return (
                    <div id={period._id} key={period._id}>
                        <ScenarioPeriod position={index} period={period} />
                    </div>
                );
            })}
            <ImageGalleryModal />
        </div>
    );
});
