import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { AddImageBlock } from "images/AddImageBlock";
import { NewFolderBlock } from "folders/NewFolderBlock";
import { foldersStore } from "folders/_stores/foldersStore";
import clsx from "clsx";
import Folder from "@material-ui/icons/Folder";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import { FoldersLoaded } from "folders/FoldersLoaded";
import { ImageGalleryLoaded } from "images/ImageGallery";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { useTranslation } from "react-i18next";
import { IImageMdl, TDataDropBox } from "images/_models/ImageMdl";
import styles from "folders/_css/newFolderBlock.module.css";
import { observer } from "mobx-react";
import { FolderDialog } from "folders/FolderDialog";
import { IFolderMdl } from "folders/_models/FolderMdl";
import { imagesStore } from "images/_stores/imagesStore";
import { BreadcrumbFolder } from "folders/BreadcrumbFolder";
import { UiButton } from "common/ui/mui/buttons/UiButton";
import DropboxChooser from "react-dropbox-chooser";
import { appConfig } from "_configs/appConfig";
import sharedConfig from "_configs/sharedConfig";
import { toast } from "react-toastify";
import { useLoadingFromPromise } from "common/loaders/useLoadingFromPromise";
import logoDropbox from "../assets/icons/logoDropbox.png";
import { convertToMb } from "common/_utils/fileUtils";
import { userStore } from "users/_stores/userStore";

type Props = {
    onSelect: (image: IImageMdl) => void;
    onlyUser: boolean;
    close: () => void;
};

export const FoldersAndImages = observer((props: Props) => {
    const { scenarioStore: store } = useScenarioStore();
    const { setPromise } = useLoadingFromPromise();
    const scenarioId = store.scenario._id;
    const { t } = useTranslation();
    const [folderToEdit, setFolderToEdit] = useState<Omit<IFolderMdl, "_id"> & { _id?: string }>();
    const [folderDragOver, setFolderDragOver] = useState<boolean>(false);

    return (
        <div className="height_full flex_column">
            <div className="flex-1">
                <div className={clsx("flex_row justifyContent_spaceBetween", styles.contentValidation)}>
                    <BreadcrumbFolder />
                    {imagesStore.galleryState?.multiple && imagesStore.selectedImagesForGallerySection.length > 0 && (
                        <UiButton
                            color={"primary"}
                            variant={"contained"}
                            className={styles.btnValidate}
                            onClick={() => {
                                imagesStore.galleryState?.onSelect([...imagesStore.selectedImagesForGallerySection]);
                                imagesStore.resetSelectedImagesForSection();
                                props.close();
                            }}
                        >
                            {t("editor.validateMulti")}
                        </UiButton>
                    )}
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3} lg={2}>
                        <AddImageBlock />
                        <div className={"mt_30 mb_30 flex_row"}>
                            <hr className={"flex-1 mr_10"} />
                            <div className={styles.lightText}> {t("words.or")}</div>
                            <hr className={"flex-1 ml_10"} />
                        </div>
                        <div className={styles.containerDropbox}>
                            <DropboxChooser
                                className={styles.dropbox}
                                appKey={appConfig.dropBox.apiKey}
                                success={async (files: TDataDropBox[]) => {
                                    if (!files || files.length === 0) return;
                                    if ([...files].some((file) => file.bytes > sharedConfig.maxUploadSize)) {
                                        toast.error(
                                            t("editor.maxSizeError", {
                                                maxSize: convertToMb(sharedConfig.maxUploadSize),
                                            }),
                                        );
                                        return;
                                    }
                                    const newFiles = await Promise.all([
                                        ...files.map(async (_file) => {
                                            const response = await fetch(_file.link);
                                            const blob = await response.blob();
                                            return new File([blob], _file.name, { type: blob.type });
                                        }),
                                    ]);

                                    setPromise(
                                        imagesStore
                                            .createFromBlobs(newFiles, scenarioId, foldersStore.selectedFolderId)
                                            .catch(() => {
                                                toast.error(t("editor.imagesUploadError"));
                                            }),
                                    );
                                }}
                                extensions={["image/png", "image/gif", "image/jpeg", "image/svg+xml"]}
                                multiselect={true}
                                linkType={"direct"}
                            >
                                <img src={logoDropbox} alt={"Logo dropbox"} className={"dropbox-button"} height={50} />
                            </DropboxChooser>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3} lg={2}>
                        <NewFolderBlock
                            text={t("editor.addFolder")}
                            onClick={() => {
                                setFolderToEdit({
                                    scenarioId: store.scenario._id,
                                    name: "New folder",
                                    parentFolderId: foldersStore.selectedFolderId,
                                    userId: userStore.user?._id,
                                });
                            }}
                        />
                    </Grid>

                    {!!foldersStore.selectedFolder && (
                        <>
                            <Grid item xs={12} md={3} lg={2}>
                                <div
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setFolderDragOver(true);
                                    }}
                                    onDragLeave={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setFolderDragOver(false);
                                    }}
                                    onDrop={(_e) => {
                                        if (foldersStore.draggedImage) {
                                            imagesStore.update({
                                                ...foldersStore.draggedImage,
                                                folderId: foldersStore.selectedFolder?.parentFolderId ?? null,
                                            });
                                        }
                                        setFolderDragOver(false);
                                    }}
                                    className={clsx(styles.container, "flex_center_center", {
                                        [styles.isDraggedOver]: folderDragOver,
                                    })}
                                    onClick={() =>
                                        foldersStore.setSelectedFolder(foldersStore.selectedFolder?.parentFolderId)
                                    }
                                >
                                    <Folder style={{ fontSize: "5rem" }} />
                                    <div className={clsx(styles.text, "flex_row_center")}>
                                        <ArrowUpward />
                                        {foldersStore.getNameParentFolder()}
                                    </div>
                                </div>
                            </Grid>
                        </>
                    )}
                    <FoldersLoaded
                        onFolderEdit={(folder) => setFolderToEdit(folder)}
                        onFolderClick={(folderId) => foldersStore.setSelectedFolder(folderId)}
                    />
                    <ImageGalleryLoaded {...props} onlyUser={props.onlyUser} />
                    <FolderDialog
                        open={!!folderToEdit}
                        onClose={() => setFolderToEdit(undefined)}
                        onSuccess={(folder) => {
                            if (!folder._id) foldersStore.createFolder(folder);
                            else foldersStore.updateFolder(folder._id, { ...folder });
                            setFolderToEdit(undefined);
                        }}
                        folder={folderToEdit}
                    />
                </Grid>
            </div>
        </div>
    );
});
