import React from "react";
import styles from "scenarios/periods/_css/scenarioPeriod.module.css";
import { useScenarioStore } from "scenarios/ScenarioContext";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

type Props = {
    onSuccess?: () => void;
};

export function AddPeriodBtn(props: Props) {
    const { t } = useTranslation();
    const store = useScenarioStore();
const { scenarioStore } = store;
    if (scenarioStore.isCollaborator()) return null;
    return (
        <div
            onClick={props.onSuccess}
            className={clsx("position_relative cursor_pointer", styles.addChapterBtnTooltipContainer)}
        >
            <div className={clsx("position_absolute", styles.addChapterBtnTooltip)}>
                {t("editor.period.addBtnTooltip")}
            </div>
            <svg
                className={styles.dotAdd}
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="16" cy="16" r="16" fill="#435780" />
                <path
                    d="M22.6663 16.9524H16.9521V22.6667H15.0473V16.9524H9.33301V15.0477H15.0473V9.33337H16.9521V15.0477H22.6663V16.9524Z"
                    fill="#fff"
                />
            </svg>
        </div>
    );
}
