import React from "react";
import { EventTag } from "scenarios/events/EventTag";
import i18next from "i18next";
import { bookmarksStore } from "bookmarks/_stores/bookmarksStore";
import { TEventMdl } from "scenarios/_models/EventMdl";
import { BOOKMARK_ICON_BASE_URL } from "bookmarks/_configs/bookmarkIcons";
import { useScenarioStore } from "scenarios/ScenarioContext";
import { TLang } from "_configs/sharedConfig";

type Props = {
    event: TEventMdl;
    onTemporaryEventSave: (ev: TEventMdl) => void;
};

export const EventBookmarksList = (props: Props) => {
    const store = useScenarioStore();
const { scenarioStore } = store;

    return (
        <>
            {bookmarksStore.bookmarks.concat(...scenarioStore.scenario.bookmarks).map((bookmark, index) => {
                const isSelectedIndex = props.event.bookmarks.findIndex(
                    (eventBookmark) => eventBookmark === bookmark._id,
                );

                return (
                    <EventTag
                        onToggleEvent={() => {
                            if (scenarioStore.isCollaboratorEventAuthorOrScenarioOwner(props.event)) {
                                const event = { ...props.event, bookmarks: props.event.bookmarks.slice() };
                                if (isSelectedIndex > -1) {
                                    event.bookmarks.splice(isSelectedIndex, 1);
                                } else {
                                    event.bookmarks = [...event.bookmarks, bookmark._id];
                                }

                                props.onTemporaryEventSave(event);
                            }
                        }}
                        isSelected={isSelectedIndex > -1}
                        key={index}
                        title={
                            typeof bookmark.title === "string"
                                ? bookmark.title
                                : bookmark.title[i18next.language as TLang]
                        }
                        imageUrl={BOOKMARK_ICON_BASE_URL + bookmark.icon + ".svg"}
                    />
                );
            })}
        </>
    );
};
