import React, { useContext } from "react";
import { ScenarioStore } from "scenarios/_stores/ScenarioStore";

const ScenarioFormCtxt = React.createContext<{ scenarioStore: ScenarioStore; publicScenarioView?: boolean }>({
    scenarioStore: {} as ScenarioStore,
    publicScenarioView: undefined,
});

type Props = {
    scenarioStore: ScenarioStore;
    public?: boolean;
};

export function useScenarioStore() {
    return useContext(ScenarioFormCtxt);
}

export function ScenarioContext(props: React.PropsWithChildren<Props>) {
    return (
        <ScenarioFormCtxt.Provider value={{ scenarioStore: props.scenarioStore, publicScenarioView: props.public }}>
            {props.children}
        </ScenarioFormCtxt.Provider>
    );
}
